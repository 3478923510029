import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Typography } from '@material-ui/core';
import axios from 'axios';

function AllChats() {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios.get('/api/message/admin', {
        headers: {
          'x-access-token': sessionStorage.getItem('token')
        }
      })
      .then(response => {
        setMessages(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setError('Failed to fetch messages');
        setLoading(false);
      });
  }, []);

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Conversation ID</TableCell>
            <TableCell>Message ID</TableCell>
            <TableCell>Sent By</TableCell>
            <TableCell>Sent To</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>Message</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {messages.map((msg) => (
            <TableRow key={msg.message_id}>
              <TableCell>{msg.conversation_id}</TableCell>
              <TableCell>{msg.message_id}</TableCell>
              <TableCell>{msg.sent_by}</TableCell>
              <TableCell>{msg.sent_to}</TableCell>
              <TableCell>{new Date(msg.created).toLocaleString()}</TableCell>
              <TableCell>{msg.message}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default AllChats;
