import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ReportIcon from '@material-ui/icons/Report'
import AddIcon from '@material-ui/icons/Add'
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ReceiptIcon from '@material-ui/icons/Receipt'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

export default function AdminList({ open, setOpen }) {
  const classes = useStyles();

  const handleClick = () => {
    setOpen({...open, admin: !open.admin});
  };

  return (
    <div>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <SupervisorAccountIcon />
        </ListItemIcon>
        <ListItemText primary="Admin" />
        {open.admin ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open.admin} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemLink className={classes.nested} button component={Link} to='/admin/bid_ask'>
            <ListItemIcon>
              <MonetizationOnIcon />
            </ListItemIcon>
            <ListItemText primary="Bid/Ask" />
          </ListItemLink>
          <ListItemLink className={classes.nested} button component={Link} to='/admin/chats'>
            <ListItemIcon>
              <MenuBookIcon />
            </ListItemIcon>
            <ListItemText primary="Chats" />
          </ListItemLink>
          <ListItemLink className={classes.nested} button component={Link} to='/admin/user'>
            <ListItemIcon>
              <AccountBoxIcon />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItemLink>
          <ListItemLink className={classes.nested} button component={Link} to='/admin/team'>
            <ListItemIcon>
              <PeopleOutlineIcon />
            </ListItemIcon>
            <ListItemText primary="Teams" />
          </ListItemLink>
          <ListItemLink className={classes.nested} button component={Link} to='/admin/booking'>
            <ListItemIcon>
              <MenuBookIcon />
            </ListItemIcon>
            <ListItemText primary="Bookings" />
          </ListItemLink>
          <ListItemLink className={classes.nested} button component={Link} to='/admin/reports'>
            <ListItemIcon>
              <ReportIcon />
            </ListItemIcon>
            <ListItemText primary="Reports" />
          </ListItemLink>
          <ListItemLink className={classes.nested} button component={Link} to='/admin/requests'>
            <ListItemIcon>
              <ReceiptIcon />
            </ListItemIcon>
            <ListItemText primary="Requests" />
          </ListItemLink>
          <ListItemLink className={classes.nested} button component={Link} to='/admin/products'>
            <ListItemIcon>
              <AddIcon/>
            </ListItemIcon>
            <ListItemText primary="Add Product" />
          </ListItemLink>
        </List>
      </Collapse>
    </div>
  );
}
