import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Navigation from './navigation/Navigation';
import Bids from './bid/Bids';
import Landing from './landing/Landing';
import MyBookingsPage from './booking/MyBookingsPage';
import AdminBooking from './booking/AdminBooking'
import UsersPage from './user/UsersPage';
import MyStorageTable from './storage/MyStorageTable';
import MyListingsPage from './listing/MyListingsPage';
import MyInquiriesPage from './inquiry/MyInquiriesPage';
import AllListingsTable from './listing/AllListingsTable';
import AllInquiriesTable from './inquiry/AllInquiriesTable';
import AddProduct from './inputs/AddProduct';
import Dashboard from './dashboard/Dashboard';
import AdminRoute from '../routes/AdminRoute';
import Profile from './user/Profile';
import ErrorPage from './error/ErrorPage';
import Unsubscribe from './user/Unsubscribe';
import Messages from './navigation/Messages';
import Reports from './user/Reports';
import Requests from './user/Requests';
import AdminTeamTable from './team/AdminTeamTable';
import MyTeamPage from './team/MyTeamPage';
import AcceptInvite from './team/AcceptInvite';
import TeamStorageTable from './storage/TeamStorageTable';
import TeamListingsPage from './listing/TeamListingsPage';
import TeamInquiriesPage from './inquiry/TeamInquiriesPage';
import TeamBids from './bid/TeamBids';
import TeamBookingsPage from './booking/TeamBookingsPage';
import TutorialBar from './display/TutorialBar'
import TutorialScreen from './inputs/TutorialScreen'
import ContactUs from './inputs/ContactUs'
import BidAskTable from './bid_ask/BidAskTable'
//import Listing from './listing/Listing';
import AllChats from './chat/AllChats';
import {
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";
import { useAuth } from '../context/auth';
import SessionExpired from './auth/SessionExpired';
import { registerOnMessageCallback, startWebsocketConnection } from '../server/websocket'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://thetanktiger.com/">
        The Tank Tiger
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  messages: {
    position: 'absolute',
    bottom: '5px',
    right: '30px',
    'z-index': 3
  }
}));

export default function Home() {
  const classes = useStyles();
  let { path } = useRouteMatch();
  const { user_id } = useAuth(sessionStorage.getItem('user_id'))
  const { userCategory } = useAuth()
  const [notifications, setNotifications] = useState(false)
  const [chat, setChat] = useState([])

  const onMessageReceived = (msg) => {
      let params = JSON.parse(msg)
      console.log('params: ', params)
      if (params.type === 'notify'){
        setNotifications(!notifications)
        var notification = new Notification('You have received a new notification.')
        notification.onclick = function () {
          window.focus()
        }
      } else {
        let temp = Object.assign({},params)
        setChat(temp)
        var notification = new Notification('You have received a new message.')
        notification.onclick = function () {
          window.focus()
        }
      }
  }

  useEffect(() => {
    startWebsocketConnection(user_id)
    registerOnMessageCallback(onMessageReceived)
    Notification.requestPermission()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_id])

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Navigation notify = {notifications}/>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth={false} className={classes.container}>
          <Switch>
            <Route exact path={path} component={Dashboard}/>
            <Route path = {`${path}user/unsubscribe`} component={Unsubscribe}/>
            <AdminRoute path={`${path}admin/user`} component={UsersPage}/>
            <AdminRoute path={`${path}admin/team`} component={AdminTeamTable}/>
            <AdminRoute path={`${path}admin/booking`} component={AdminBooking}/>
            <AdminRoute path={`${path}admin/reports`} component={Reports}/>
            <AdminRoute path={`${path}admin/requests`} component={Requests}/>
            <AdminRoute path={`${path}admin/bid_ask`} component={BidAskTable}/>
            <AdminRoute path={`${path}admin/products`} component={AddProduct}/>
            <AdminRoute path={`${path}admin/chats`} component={AllChats}/>
            <Route path={`${path}listing`} component={AllListingsTable}/>
            <Route path={`${path}inquiry`} component={AllInquiriesTable}/>
            <Route path={`${path}my/storage`} component={MyStorageTable}/>
            <Route path={`${path}my/listing`} component={MyListingsPage}/>
            <Route path={`${path}my/bid`} component={Bids}/>
            <Route path={`${path}my/booking`} component={MyBookingsPage}/>
            <Route path={`${path}my/inquiry`} component={MyInquiriesPage}/>
            <Route path={`${path}team/members`} component={MyTeamPage}/>
            <Route path={`${path}team/storage`} component={TeamStorageTable}/>
            <Route path={`${path}team/listing`} component={TeamListingsPage}/>
            <Route path={`${path}team/inquiry`} component={TeamInquiriesPage}/>
            <Route path={`${path}team/booking`} component={TeamBookingsPage}/>
            <Route path={`${path}team/bid`} component={TeamBids}/>
            <Route path={`${path}team/accept/:token`} component={AcceptInvite}/>
            <Route path = {`${path}landing/:table/:id`} component={Landing}/>
            <Route path = {`${path}profile`} component={Profile}/>
            <Route path = {`${path}ERROR/:error`} component={ErrorPage}/>
            <Route path = {`${path}contactus`} component={ContactUs}/>
          </Switch>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
      <div className = {classes.messages}>
        {userCategory !== 'Viewer' &&
          <Messages message = {chat}/>
        }
      </div>
      <SessionExpired/>
      <TutorialBar/>
      <TutorialScreen/>
    </div>
  );
}
